import Gen from "@helper/Gen"
var fo_router = [
    {
        name: "Home",
        path: "",
        component: () => import("./frontend/Home.vue"),
    },{
        name: "ProfilYayasan",
        path: "profil-yayasan",
        component: () => import("./frontend/ProfilYayasan.vue"),
    },{
        name: "Program",
        path: "program",
        component: () => import("./frontend/Program.vue"),
    },{
        name: "News",
        path: "berita",
        component: () => import("./frontend/News.vue"),
    },{
        name: "DetailBlog",
        path: "berita/:slug",
        component: () => import("./frontend/DetailBlog.vue"),
    },{
        name: "DetailTags",
        path: "tags/:slug",
        component: () => import("./frontend/Detailtags.vue"),
    },{
        name: "Contact",
        path: "kontak-kami",
        component: () => import("./frontend/Contact.vue"),
    },{
        name: "PrivacyPolicy",
        path: "privacy-policy",
        component: () => import("./frontend/PrivacyPolicy.vue"),
    },{
        name: "TermCondition",
        path: "term-and-condition",
        component: () => import("./frontend/TermCondition.vue"),
    },{
        name: "404",
        path: "*",
        component: () => import("./frontend/Page404.vue"),
    },

]
fo_router.map((v)=>{
    v.beforeEnter = (to, from, next)=>{
        if(typeof $ != 'undefined') $("html,body").scrollTop(0)
        next()
    }
})
var data = [
    {
        name: "logout",
        path: "logout",
        beforeEnter(from, to, next){
            Gen.delCookie("fotk")
            Gen.delStorage("fotk")
            if(typeof App != "undefined") App.user = null
            next({name:'index'})
        },
    },
    {
        path:"/",
        component: () => import('@/layout/FoLayout.vue'),
        children: fo_router
    },
]
export default data